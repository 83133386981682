<template>
    <div class="height1">
        <bread></bread>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form :model="searchForm" :inline="true">
                            <el-form-item>
                                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions0" size="small" style="margin-right: 10px">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item>
                                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="输入关键词搜索..."
                                    @keyup.enter.native="SearchInputKeydown"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="Search()">搜索</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <el-table :data="tableData" stripe height="calc(100% - 100px)" style="width: 100%" :header-cell-style="{background:'#F5F7FC'}">
                    <el-table-column prop="orderNum" label="订单编号" width="280px">
                    </el-table-column>
                    <el-table-column prop="createTime" label="时间" width="220px">
                        <template slot-scope="scope"> {{ scope.row.createTime | timefilters }} </template>
                    </el-table-column>
                    <el-table-column prop="purpose" label="类型">
                        <template slot-scope="scope">
                            <el-tag size="small" v-if="scope.row.purpose == 'withdrawal'">提现</el-tag>
                            <el-tag size="small" v-if="scope.row.purpose == 'refund'">退款</el-tag>
                            <el-tag size="small" v-if="scope.row.purpose == 'buyMember'">购买会员</el-tag>
                            <el-tag size="small" v-if="scope.row.purpose == 'upgradeMember'">升级会员
                            </el-tag>
                            <el-tag size="small" v-if="scope.row.purpose == 'renewalUpgrade'">续费升级订单
                            </el-tag>
                            <el-tag size="small" v-if="scope.row.purpose == 'renewalMember'">续费会员
                            </el-tag>
                            <el-tag size="small" v-if="scope.row.purpose == 'payVideo'">购买视频</el-tag>
                            
                            <!-- 收入 -->
                            <el-tag size="small" v-if="scope.row.purpose == 'recharge'">充值</el-tag>
                            <el-tag size="small" v-if="scope.row.purpose == 'entryGoods'">商品入账
                            </el-tag>
                            <el-tag size="small" v-if="scope.row.purpose == 'distribution'">分销获利</el-tag>
                            <el-tag size="small" v-if="scope.row.purpose == 'shareMoney'">分享获利</el-tag>
                            <el-tag size="small" v-if="scope.row.purpose == 'shareMoneySecond'">分享获利抽佣</el-tag>
                            <el-tag size="small" v-if="scope.row.purpose == 'publicShare'">公益分红</el-tag>
                            <el-tag size="small" v-if="scope.row.purpose == 'purchaseGoods'">购买商品
                            </el-tag>
                            <el-tag size="small" v-if="scope.row.purpose == 'refundChargebackMoney'">
                                退款手续费</el-tag>
                            <el-tag size="small" v-if="scope.row.purpose == 'saleVideo'">出售视频</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="type" label="账单类型" align="center">
                        <template slot-scope="scope">
                            <el-tag type="warning" size="small" v-if="scope.row.type == '1'">支出</el-tag>
                            <el-tag type="success" size="small" v-if="scope.row.type == '2'">收入</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="amount" label="金额（元）">
                    </el-table-column>
                    <el-table-column prop="state" label="状态">
                        <template slot-scope="scope">
                            <el-tag size="small" type="success" v-if="scope.row.state == 1">已到账</el-tag>
                            <el-tag size="small" v-if="scope.row.state == 0">待入账</el-tag>
                            <el-tag size="small" type="danger" v-if="scope.row.state == -1">已拒绝</el-tag>
                            <el-tag size="small" type="warning" v-if="scope.row.state == -2">已取消</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="paymentMethod" label="支付方式">
                        <template slot-scope="scope">
                            <span v-if="scope.row.paymentMethod == '1'">微信</span>
                            <span v-if="scope.row.paymentMethod == '2'">支付宝</span>
                            <span v-if="scope.row.paymentMethod == '0'">余额</span>
                            <span v-if="scope.row.paymentMethod == '3'">银行卡</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="state" label="开票状态">
                        <template slot-scope="scope">
                            <el-tag type="info" size="small"
                                v-if="scope.row.isInvoiced == 0 && purpose.some(info => info == scope.row.purpose)">未开票
                            </el-tag>
                            <el-tag type="warning" size="small"
                                v-else-if="scope.row.isInvoiced == 10 && purpose.some(info => info == scope.row.purpose)">
                                开票中</el-tag>
                            <el-tag type="success" size="small"
                                v-else-if="scope.row.isInvoiced == 11 && purpose.some(info => info == scope.row.purpose)">
                                已开票</el-tag>
                            <span v-else>-</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="fullName"
                                     label="操作账号">
                    </el-table-column>-->
                    <el-table-column fixed="right" label="操作">
                        <template slot-scope="scope">
                            <span class="primary optionBtn"
                                v-if="scope.row.isInvoiced == 0 && purpose.some(info => info == scope.row.purpose)"
                                @click="invoiceBtn(scope.row)">开票申请</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                    :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </div>
        </div>
        <!--    开票申请    -->
        <el-dialog title="开票申请" :visible.sync="invoiceDialog">
            <el-form :model="invoiceForm" ref="invoiceForm">
                <el-form-item label="发票类型" :label-width="formLabelWidth">
                    <el-radio-group v-model="invoiceForm.invoiceType">
                        <el-radio :label="0">增值税普通发票</el-radio>
                        <el-radio :label="1">增值税专用发票</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <el-alert title="如果发票信息有误或者更改，请及时修改发票信息，以免造成不必要损失" type="error" show-icon :closable="false"
                style="margin-bottom: 15px;">
            </el-alert>
            <el-descriptions class="margin-top" :column="1" border>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-user"></i>
                        企业名称
                    </template>
                    <el-select v-model="invoiceForm.invoiceId" value-key="id" placeholder="请选择"
                        @change="selectInvoice($event)">
                        <el-option v-for="item in InvoiceData" :key="item.id" :label="item.name" :value="item">
                        </el-option>
                    </el-select>
                    <el-link style="margin-left: 20px" type="primary" @click="toAddInvoice">添加发票信息</el-link>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-mobile-phone"></i>
                        税号
                    </template>
                    {{ invoiceDetail.invoiceNo }}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-location-outline"></i>
                        地址
                    </template>
                    {{ invoiceDetail.address }}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-tickets"></i>
                        电话
                    </template>
                    {{ invoiceDetail.phone }}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-office-building"></i>
                        开户行
                    </template>
                    {{ invoiceDetail.bank }}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-office-building"></i>
                        开户账号
                    </template>
                    {{ invoiceDetail.bankNo }}
                </el-descriptions-item>
            </el-descriptions>

            <div slot="footer" class="dialog-footer">
                <el-button @click="colseBtn('invoiceForm')">取 消</el-button>
                <el-button type="primary" @click="invoiceSave('invoiceForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import bread from "../../../components/common/breadcrumb"
import { Distributor } from "../../../components/HospitalDomain/Distributor";
import { Invoice } from "../../../components/HospitalDomain/Invoice";
export default {
    name: "Bill",
    components: {
        bread
    },
    data() {
        var distributor = new Distributor(this.TokenClient, this.Services.Authorization);
        var invoice = new Invoice(this.TokenClient, this.Services.Authorization)
        return {
            DistributorDomain: distributor,
            Invoice: invoice,
            tableData: [],
            searchForm: {
                type: '0',
                statrTime: '',
                endTime: '',
                keyWord: '',
                datepicker: ''
            },
            pickerOptions0: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            purpose: ['upgradeMember', 'renewalMember', 'buyMember'],
            organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
            userId: JSON.parse(sessionStorage.getItem('userInfo')).id,
            PageIndex: 1,
            PageTotal: 1,
            PageSize: 15,
            DataTotal: 0,
            invoiceDialog: false,
            formLabelWidth: '120px',
            invoiceForm: {
                invoiceType: 0,
            },
            InvoiceData: [],
            invoiceDetail: {},
        }
    },
    mounted() {
        this.getList();
        this.getInvoiceInfo();
    },
    methods: {
        SearchInputKeydown(event) {
            if (event.keyCode == 13) {

            }
        },
        Search(event) {
            this.PageIndex = 1;
            this.getList()
        },
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            this.getList();
        },
        getList() {
            var _this = this;
            var item = _this.searchForm;
            if (_this.searchForm.datepicker) {
                item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00';
                item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59';
            } else {
                item.startTime = "";
                item.endTime = "";
            }
            _this.DistributorDomain.BillList(_this.organizationId, item.startTime, item.endTime, item.keyWord, item.type, _this.PageIndex,
                function (data) {
                    _this.tableData = data.data.results;
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageSize = data.data.pageSize;
                    _this.PageTotal = data.data.pageCount;
                    _this.DataTotal = data.data.dataTotal;
                },
                function (error) {
                    console.log(error);
                });
        },
        invoiceBtn(item) {
            this.invoiceDialog = true
            this.invoiceForm.money = item.amount
            this.invoiceForm.assetsDetailsIds = item.id
        },
        getInvoiceInfo() {
            var _this = this;
            _this.InvoiceData = [];
            _this.Invoice.InvoiceInfo(_this.organizationId, function (data) {
                _this.InvoiceData = data.data;
            }, function (err) { console.log(err); });
        },
        selectInvoice(even) {
            this.invoiceDetail = even;
            console.log(even)
        },
        invoiceSave() {
            var _this = this;
            var parmas = {
                invoiceType: _this.invoiceForm.invoiceType,
                organizationId: _this.organizationId,
                invoiceKind: 0,
                invoiceInfoId: _this.invoiceForm.invoiceId.id,
                operationUserId: _this.userId,
                money: _this.invoiceForm.money,
                assetsDetailsIds: [_this.invoiceForm.assetsDetailsIds.toString()]
            }
            _this.Invoice.SubmitInvoice(parmas, function (data) {
                _this.$message({
                    type: 'success',
                    message: '提交成功!'
                });
                _this.invoiceDialog = false;
                _this.getList();
            }, function (err) { console.log(err); });
        },
        toAddInvoice() {
            this.$router.push('/System/Invoice',)
        }
    }
}
</script>

<style scoped>
</style>
